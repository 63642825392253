import {createRef, Component, RefObject} from 'react';
import ReactDOMServer from 'react-dom/server'
import classNames from 'classnames';
import styles from './styles/LeafletMap.module.scss';
import CategoryMapMarker from "../map/CategoryMapMarker";

export type IModifiedLocation = {
    location: ILocation & ExpandedOrganization,
    lat: number,
    lng: number,
    address: JSX.Element | null,
    customIcon?: any,
}

export default class LeafletMap extends Component<{
    locations: Array<ILocation & ExpandedOrganization>,
    center: [string, string],
    address: string | JSX.Element,
    zoom: number,
    handleMarkerClick: (location: ILocation & ExpandedOrganization) => void,
    sepia?: boolean,
    cluster?: boolean,
    markerOutline?: boolean,
    loadTiles?: boolean,
}, {
    draggable: boolean,
    displayInsert: boolean,
    components?: any,
}> {



    static defaultProps = {
        center: [39.400641, -107.211390],
        zoom: 15,
        address: '',
        loadTiles: true,
        // draggable: false,
        handleMarkerClick: () => {
        },
    };

    constructor(props) {
        super(props);

        this.state = {
            displayInsert: true,
            draggable: false,
            components: undefined
        };

        this.mapRef = createRef();
    }

    componentDidMount() {
        // const leaflet = require('leaflet');
        // leaflet.Map.prototype.getCon
        const {
            Map,
            Marker,
            Popup,
            TileLayer,
            ZoomControl,
        } = require('react-leaflet');
        const {icon, divIcon, point} = require('leaflet');
        const MarkerClusterGroup = require('react-leaflet-markercluster').default;
        this.setState({
            components: {
                icon,
                divIcon,
                point,
                Map,
                Marker,
                Popup,
                TileLayer,
                MarkerClusterGroup,
                ZoomControl,
            },
        });
    }
    mapRef: RefObject<any>

    handleClick = () => {
        if (!this.state.draggable) {
            this.setState({
                draggable: true,
            });
        }
    };

    getMapRef = () => {
        return this.mapRef.current;
    };

    render() {
        const {zoom} = this.props;

        if (!this.state.components) {
            return <div/>;
        }

        const modifiedLocations: IModifiedLocation[] = this.props.locations
            .filter(l => l.coordinates)
            .map(location => {
                const coordinateArray = (location.coordinates as string).replace(/[()]/g, "").split(",");
                let address: IModifiedLocation["address"] = null;
                let customIcon: IModifiedLocation["customIcon"]= null;
                if (location.address &&
                    location.city &&
                    location.state &&
                    location.zip) {
                    address =
                        <address className={"notranslate"}>
                            <div>{location.address}</div>
                            <div>{location.city}, {location.state} {location.zip}</div>
                        </address>
                }
                if (location.category) {
                    customIcon =
                        <>
                            {this.props.markerOutline && <div className={styles.pin}/>}
                            <CategoryMapMarker lng={coordinateArray[1]} lat={coordinateArray[0]} category={location.category}/>
                        </>
                }
                return {
                    lat: parseFloat(coordinateArray[0]),
                    lng: parseFloat(coordinateArray[1]),
                    address,
                    location,
                    customIcon,
                }
            })

        const leafletMapContainerClassName = classNames({
            [styles.leafletMapContainer]: true,
            [styles.leafletMapContainerGrayscale]: true,
        });

        const {
            icon,
            divIcon,
            Map,
            Marker,
            Popup,
            point,
            TileLayer,
            MarkerClusterGroup,
            ZoomControl,
        } = this.state.components;

        const createClusterCustomIcon = function (cluster) {
            const count = cluster.getChildCount();

            const clusterClassName = classNames({
                [styles.cluster]: true,
                [styles.clusterOrange]: count >= 10 && count < 20,
                [styles.clusterRed]: count >= 20,
            });

            return divIcon({
                html: `<span>${count}</span>`,
                className: clusterClassName,
                iconSize: point(64, 64, true),
            });
        };

        const markers = modifiedLocations.map((item, i) => {
            let myIcon = icon({
                iconUrl: require("../../assets/images/marker.svg"),
                iconSize: [48, 48],
                iconAnchor: [24, 48],
                popupAnchor: [0, -48],
                shadowUrl: null,
                shadowSize: null,
                shadowAnchor: null
            });
            if (item.location.category) {
                myIcon = divIcon({
                    html: ReactDOMServer.renderToString(item.customIcon),
                    className: 'custom-icon',
                    iconSize: [30, 30],
                    iconAnchor: [15, 30],
                    popupAnchor: [0, -30],
                    shadowUrl: null,
                    shadowSize: null,
                    shadowAnchor: null
                })
            }

            return (
                <Marker onClick={() => this.props.handleMarkerClick(item.location)}
                        icon={myIcon}
                        alt={item.location.title}
                        key={`${item.lat.toString()}${item.lng.toString()}${i}`}
                        position={[item.lat, item.lng]}>
                    {item.location.title && <Popup key={`${item.location.title}`}>
                        <span>{`${item.location.title}`}</span>
                    </Popup>}
                </Marker>
            )
        })

        return (
            <Map center={this.props.center}
                 zoom={zoom}
                 className={leafletMapContainerClassName}
                 scrollWheelZoom={false}
                 maxZoom={18}
                 dragging={this.state.draggable}
                 onClick={this.handleClick}
                 bounds={modifiedLocations.length > 0 ? modifiedLocations.map(item => {
                     return [item.lat, item.lng]
                 }) : null}
                 boundsOptions={{
                     maxZoom: zoom,
                 }}
                 preferCanvas={false}
                 ref={this.mapRef}
                 zoomControl={false}
            >
                {this.props.loadTiles && <TileLayer
                    url="https://api.mapbox.com/styles/v1/davidgolden/cjq5chqca7mpo2rs06gh00eid/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZGF2aWRnb2xkZW4iLCJhIjoiY2pwZW5oamdwMDIyejNrbzhrMGc4YzlqNiJ9.klBRb1BeqxvmJjqi9f-8yw"
                    attribution='SchoolBlocks - Click to Drag'
                    setParams={true}
                />}
                {this.props.cluster ?
                    <MarkerClusterGroup
                        showCoverageOnHover={false}
                        iconCreateFunction={createClusterCustomIcon}
                    >
                        {markers}
                    </MarkerClusterGroup>
                    : <div>
                        {markers}
                    </div>
                }

                <ZoomControl position={'bottomright'}/>
            </Map>
        );
    }
}

