import AdultEducation_Other_Education from "../../assets/images/category_icons/adultedu_otherschools_education.svg";
import District from "../../assets/images/category_icons/district.svg";
import ElementarySchool_PrimarySchool from "../../assets/images/category_icons/elementaryschools_primaryschools.svg";
import HighSchool_JuniorHighSchool_AlternativeSchool from "../../assets/images/category_icons/highschools_juniorhighschools_alternativeschools.svg";
import MiddleSchool_CharterSchool_IntermediateSchool from "../../assets/images/category_icons/middleschools_charterschools_intermediateschools.svg";
import PreSchool_HeadStartProgram from "../../assets/images/category_icons/preschools_headstartprograms.svg";
import DesignMediaInnovation from "../../assets/images/category_icons/design_media_innovation.svg";
import PerformingArt from "../../assets/images/category_icons/performingart.svg";
import Wellness from "../../assets/images/category_icons/wellness.svg";
import Heritage from "../../assets/images/category_icons/heritage.svg";
import StudioArts from "../../assets/images/category_icons/studio_arts.svg";
import Culinary from "../../assets/images/category_icons/culinary.svg";

const categoryIcons = {
    "adultedu": AdultEducation_Other_Education,
    "specialed": AdultEducation_Other_Education,
    "otherschools": AdultEducation_Other_Education,
    "education": AdultEducation_Other_Education,
    "onlineschools": AdultEducation_Other_Education,
    "district": District,
    "elementaryschools": ElementarySchool_PrimarySchool,
    "primaryschools": ElementarySchool_PrimarySchool,
    "highschools": HighSchool_JuniorHighSchool_AlternativeSchool,
    "juniorhighschools": HighSchool_JuniorHighSchool_AlternativeSchool,
    "alternativeschools": HighSchool_JuniorHighSchool_AlternativeSchool,
    "middleschools": MiddleSchool_CharterSchool_IntermediateSchool,
    "charterschools": MiddleSchool_CharterSchool_IntermediateSchool,
    "intermediateschools": MiddleSchool_CharterSchool_IntermediateSchool,
    "preschools": PreSchool_HeadStartProgram,
    "headstartprograms": PreSchool_HeadStartProgram,
    "design_media_innovation": DesignMediaInnovation,
    "performingart": PerformingArt,
    "wellness": Wellness,
    "heritage": Heritage,
    "studio_arts": StudioArts,
    "culinary": Culinary,
}

export function getIconFromAlias (alias) {
    if (!alias) {
        return null
    }
    return categoryIcons[alias]
}
