import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CategoryIcon from './CategoryIcon';

export default class CategoryMapMarker extends Component {
    static propTypes = {
        title: PropTypes.string,
        category: PropTypes.object,
        lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        lng: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        $hover: PropTypes.bool,
    };

    static defaultProps = {
        $hover: false,
    };

    render() {
        const { title, category, $hover } = this.props;

        if (category === undefined || category === null) {
            return null;
        }

        const balloonClassNames = classNames({
            'sb-map-balloon': true,
            'active': $hover,
        });
        return (
            <div className="sb-block-icon" style={{background: category.category_bg_color === 'transparent' ?
                    'transparent' : '#' + category.category_bg_color}}>
                <span>
                    <CategoryIcon alias={category.alias}/>
                </span>
                <div className={balloonClassNames}>{title}</div>
            </div>
        );
    }
}
