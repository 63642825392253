import React, {useState, useContext} from 'react';
import LeafletMap from "../../../utilities/LeafletMap";
import styles from "./styles/MapBlock.module.scss";
import classNames from "classnames";
import {observer} from "mobx-react";
import LocationBlockItem from "./LocationBlockItem";
import {StoreContext} from "../../../../stores/StoreLoader";
import {CSSTransition} from "react-transition-group";
import InlineTextEditor from "../../../utilities/InlineTextEditor";
import WatsonApi from "../../../../backends/WatsonApi";
import NotificationManager from "../../../notifications/NotificationManager";
import {runInAction} from "mobx";
import dynamic from "next/dynamic";

const EditDropDown = dynamic(() => import('../EditDropDown'));

const mapClassName = classNames({
    [styles.map]: true,
});
const mapBlockContainerClassName = classNames({
    [styles.mapBlockContainer]: true,
});
const organizationTitleClassName = classNames({
    [styles.organizationTitle]: true,
});
const mapInsertClassName = classNames({
    [styles.mapInsert]: true,
});

const transitionClassNames = {
    enter: styles.transitionEnter,
    enterActive: styles.transitionEnterActive,
    enterDone: styles.transitionEnterDone,
    exit: styles.transitionExit,
    exitActive: styles.transitionExitActive,
    exitDone: styles.transitionExitDone,
};

const MapBlock = observer((props: {
    blockObj: IMapTerrace,
    aboveTheFold: boolean,
}) => {
    const {organizationStore, userStore, gridStore} = useContext(StoreContext);
    const [title, setTitle] = useState(props.blockObj.title);

    const [currentExpandedLocation, setCurrentExpandedLocation] = useState<((ILocation & ExpandedOrganization)) | null | undefined>(() => {
        return props.blockObj.locations.find(l => l.organization.id === organizationStore.organization.id);
    })
    const location = props.blockObj.locations[0];

    const toggleInsertOpen = (location: (ILocation & ExpandedOrganization) | null = null) => {
        setCurrentExpandedLocation(location);
    };

    let mapSubtitle: string | JSX.Element = '';
    if (location?.address) {
        mapSubtitle =
            <address className={"notranslate"}>
                <p>{location.address}</p>
                {location.address2 && <p>{location.address2}</p>}
                <p>{location.city ? `${location.city},` : ""}{location.state ? `${location.state} ` : ""}{location.zip ? location.zip : ""}</p>
            </address>
    }

    async function saveTitle() {
        try {
            const client = await WatsonApi();
            const response = await client.apis.organizations.organizations_terraces_partial_update({
                organization_pk: props.blockObj.organization_id,
                id: props.blockObj.id,
                data: {
                    title,
                },
            });
            NotificationManager.success("Title successfully saved!");
            runInAction(() => {
                gridStore.blocks.set(props.blockObj.id, JSON.parse(response.data));
            })
        } catch (e) {
            setTitle(props.blockObj.title);
            NotificationManager.error("Something went wrong! Please try again.");
        }
    }

    return (
        <section className={mapBlockContainerClassName}>
            <div className={organizationTitleClassName}>
                {userStore.isEditor && <EditDropDown
                    title={title}
                    blockObj={props.blockObj}
                    className={styles.editDropDownMap}
                />}
                <InlineTextEditor
                    key="title"
                    text={props.blockObj.title}
                    handleTextChange={(value) => setTitle(value)}
                    handleSave={saveTitle}
                    placeholder={"Enter a Title"}
                    canEdit={userStore.isEditor}
                >
                    <h3 className={title && "notranslate"}>{userStore.isEditor ? (title || "Enter a Title") : title}</h3>
                </InlineTextEditor>
            </div>
            <div className={mapClassName}>
                <LeafletMap
                    handleMarkerClick={toggleInsertOpen}
                    locations={props.blockObj.locations}
                    address={mapSubtitle}
                    cluster={true}
                    loadTiles={props.aboveTheFold}
                />
                {currentExpandedLocation && <div className={styles.locationItemTransitionWrapper}>
                    <CSSTransition appear classNames={transitionClassNames} in={props.aboveTheFold} timeout={500}>
                        <LocationBlockItem
                            className={mapInsertClassName}
                            location={currentExpandedLocation}
                            onClick={() => toggleInsertOpen()}
                        />
                    </CSSTransition>
                </div>}
            </div>
        </section>
    );
});

export default MapBlock;
