import { Component } from 'react';
import PropTypes from 'prop-types';
import {getIconFromAlias} from "./categoryIconUtilities";


export default class CategoryIcon extends Component {
    static propTypes = {
        alias: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    render() {
        const { alias } = this.props;
        let svgIcon;

        if (alias) {
            svgIcon = getIconFromAlias(alias)
        }

        let iconHtml = null;

        if (svgIcon) {
            iconHtml = <img style={{width: '100%', height: '100%'}} src={svgIcon} alt="SVG" />;
        }

        return iconHtml;
    }
}
